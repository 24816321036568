<template lang="html">
    <div class="component-wrapper" v-if="this.$store.state.searchResults">
        <SearchResults :header="'Search results'" :searchResultsData="this.$store.state.searchResults"></SearchResults>
    </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    name: 'searchResultsView',
    components: {
        SearchResults
    },
    mounted() {
    }
}
</script>

<style lang="css">
</style>
